import { getScorpMetadata } from './scorp-data'
import { Colors } from './types'

const metadata = require('./metadata.json')
const ids = Object.keys(metadata.entries)

type ColorDistribution = {
  [color: string]: ScorpCount
}

type ScorpCount = {
  occurrences: number
  scorps: {
    body_color: Set<string>
    bg2_color: Set<string>
    bg_color: Set<string>
    secondary_color: Set<string>
    outline_color: Set<string>
    eye_color: Set<string>
  }
}

const SCORP_COLORS: ColorDistribution = {}

// Construct SCORP_COLORS object
ids.forEach((scorpId: string) => {
  const metadata = getScorpMetadata(scorpId)

  const attributes = metadata.attributes
  const colors = metadata.colors

  for (const [colorLocation, hexCode] of Object.entries(colors) as [keyof Colors, string][]) {
    if (!SCORP_COLORS[hexCode]) {
      SCORP_COLORS[hexCode] = {
        occurrences: 0,
        scorps: {
          body_color: new Set(),
          bg2_color: new Set(),
          bg_color: new Set(),
          secondary_color: new Set(),
          outline_color: new Set(),
          eye_color: new Set(),
        },
      }
    }

    SCORP_COLORS[hexCode].occurrences++

    if (attributes.bg_style === 'blank' && colorLocation === 'bg_color') {
      continue
    }

    if (!attributes.multicolored && colorLocation === 'secondary_color') {
      continue
    }

    SCORP_COLORS[hexCode].scorps[colorLocation].add(scorpId)
  }
})

// Sort SCORP_COLORS.

const SORTED_COLOR_DISTRIBUTION: string[] = Object.entries(SCORP_COLORS)
  .sort(function ([_, sc1]: [string, ScorpCount], [__, sc2]: [string, ScorpCount]): number {
    if (sc1.occurrences < sc2.occurrences) {
      return -1
    }
    if (sc1.occurrences > sc2.occurrences) {
      return 1
    }
    return 0
  })
  .map(([color, _]: [string, ScorpCount]): string => color)

const NAME_LIST = {
  supraPrefixes: [
    'Dr. ',
    'Mr. ',
    'Ms. ',
    'Mrs. ',
    'Miss ',
    "O'",
    'sir ',
    'ser ',
    'mister ',
    'teacher ',
    'master ',
    'el ',
    'king ',
    'queen ',
    'duke ',
    'prince ',
    'princess ',
    'count ',
    'baron ',
    'baroness ',
    'lord ',
    'emperor ',
    'tsar ',
    'chief ',
    'pope ',
    'judge ',
    'justice ',
    'magistrate ',
    'mufti ',
    'friar ',
    'brother ',
    'sister ',
    'reverend ',
    'bishop ',
    'doc ',
    'admiral ',
    'captain ',
    'colonel ',
    'commander ',
    'officer ',
    'major ',
    'comrade ',
    'st. ',
  ],
  supraSuffixes: [
    'ton',
    'town',
    'smith',
    'maker',
    'craft',
    'crafter',
    ' expert',
    'breaker',
    'er',
    'master',
    'monger',
    'keeper',
    'stress',
    'izer',
    'lover',
    'eater',
    'sniffer',
    'licker',
    'talker',
    'spoon',
    'ish',
    'encraft',
    'incraft',
    'ness',
    'kisser',
  ],
  nonEnglishSupraSuffixes: [
    'appa',
    'chian',
    'awan',
    'cock',
    'cox',
    'datter',
    'dotter',
    'eau',
    'eault',
    'enko',
    'enya',
    'fleet',
    'ffy',
    'gil',
    'ian',
    'jian',
    'gian',
    'ich',
    'icius',
    'ipha',
    'kin',
    'kvist',
    'mohr',
    'nova',
    'ov',
    'ova',
    'shvili',
    'son',
    'sson',
    'tabar',
    'ynas',
    ' jr.',
    ' junior',
    ' senior',
    ', sr.',
    ', PhD.',
    ', B.A.',
  ],
  adjectives: [
    'adorable',
    'adventurous',
    'aggressive',
    'agreeable',
    'alert',
    'alive',
    'amused',
    'angry',
    'annoyed',
    'annoying',
    'anxious',
    'arrogant',
    'ashamed',
    'attractive',
    'average',
    'awful',
    'bad',
    'beautiful',
    'better',
    'bewildered',
    'black',
    'bloody',
    'blue',
    'blue-eyed',
    'blushing',
    'bored',
    'brainy',
    'brave',
    'breakable',
    'bright',
    'busy',
    'calm',
    'careful',
    'cautious',
    'charming',
    'cheerful',
    'clean',
    'clear',
    'clever',
    'cloudy',
    'clumsy',
    'colorful',
    'combative',
    'comfortable',
    'concerned',
    'condemned',
    'confused',
    'cooperative',
    'courageous',
    'crazy',
    'creepy',
    'crowded',
    'cruel',
    'curious',
    'cute',
    'dangerous',
    'dark',
    'dead',
    'defeated',
    'defiant',
    'delightful',
    'depressed',
    'determined',
    'different',
    'difficult',
    'disgusted',
    'distinct',
    'disturbed',
    'dizzy',
    'doubtful',
    'drab',
    'dull',
    'eager',
    'easy',
    'elated',
    'elegant',
    'embarrassed',
    'enchanting',
    'encouraging',
    'energetic',
    'enthusiastic',
    'envious',
    'evil',
    'excited',
    'expensive',
    'exuberant',
    'fair',
    'faithful',
    'famous',
    'fancy',
    'fantastic',
    'fierce',
    'filthy',
    'fine',
    'foolish',
    'fragile',
    'frail',
    'frantic',
    'friendly',
    'frightened',
    'funny',
    'gentle',
    'gifted',
    'glamorous',
    'gleaming',
    'glorious',
    'good',
    'gorgeous',
    'graceful',
    'grieving',
    'grotesque',
    'grumpy',
    'handsome',
    'happy',
    'healthy',
    'helpful',
    'helpless',
    'hilarious',
    'homeless',
    'homely',
    'horrible',
    'hungry',
    'hurt',
    'ill',
    'important',
    'impossible',
    'inexpensive',
    'innocent',
    'inquisitive',
    'itchy',
    'jealous',
    'jittery',
    'jolly',
    'joyous',
    'kind',
    'lazy',
    'light',
    'lively',
    'lonely',
    'long',
    'lovely',
    'lucky',
    'magnificent',
    'misty',
    'modern',
    'motionless',
    'muddy',
    'mushy',
    'mysterious',
    'nasty',
    'naughty',
    'nervous',
    'nice',
    'nutty',
    'obedient',
    'obnoxious',
    'odd',
    'old-fashioned',
    'open',
    'outrageous',
    'outstanding',
    'panicky',
    'perfect',
    'plain',
    'pleasant',
    'poised',
    'poor',
    'powerful',
    'precious',
    'prickly',
    'proud',
    'putrid',
    'puzzled',
    'quaint',
    'real',
    'relieved',
    'repulsive',
    'rich',
    'scary',
    'selfish',
    'shiny',
    'shy',
    'silly',
    'sleepy',
    'smiling',
    'smoggy',
    'sore',
    'sparkling',
    'splendid',
    'spotless',
    'stormy',
    'strange',
    'stupid',
    'successful',
    'super',
    'talented',
    'tame',
    'tasty',
    'tender',
    'tense',
    'terrible',
    'thankful',
    'thoughtful',
    'thoughtless',
    'tired',
    'tough',
    'troubled',
    'ugliest',
    'ugly',
    'uninterested',
    'unsightly',
    'unusual',
    'upset',
    'uptight',
    'vast',
    'victorious',
    'vivacious',
    'wandering',
    'weary',
    'wicked',
    'wide-eyed',
    'wild',
    'witty',
    'worried',
    'worrisome',
    'wrong',
    'zany',
    'zealous',
  ],
  top100us: [
    'James',
    'Mary',
    'Robert',
    'Patricia',
    'John',
    'Jennifer',
    'Michael',
    'Linda',
    'William',
    'Elizabeth',
    'David',
    'Barbara',
    'Richard',
    'Susan',
    'Joseph',
    'Jessica',
    'Thomas',
    'Sarah',
    'Charles',
    'Karen',
    'Christopher',
    'Nancy',
    'Daniel',
    'Lisa',
    'Matthew',
    'Betty',
    'Anthony',
    'Margaret',
    'Mark',
    'Sandra',
    'Donald',
    'Ashley',
    'Steven',
    'Kimberly',
    'Paul',
    'Emily',
    'Andrew',
    'Donna',
    'Joshua',
    'Michelle',
    'Kenneth',
    'Dorothy',
    'Kevin',
    'Carol',
    'Brian',
    'Amanda',
    'George',
    'Melissa',
    'Edward',
    'Deborah',
    'Ronald',
    'Stephanie',
    'Timothy',
    'Rebecca',
    'Jason',
    'Sharon',
    'Jeffrey',
    'Laura',
    'Ryan',
    'Cynthia',
    'Jacob',
    'Kathleen',
    'Gary',
    'Amy',
    'Nicholas',
    'Shirley',
    'Eric',
    'Angela',
    'Jonathan',
    'Helen',
    'Stephen',
    'Anna',
    'Larry',
    'Brenda',
    'Justin',
    'Pamela',
    'Scott',
    'Nicole',
    'Brandon',
    'Emma',
    'Benjamin',
    'Samantha',
    'Samuel',
    'Katherine',
    'Gregory',
    'Christine',
    'Frank',
    'Debra',
    'Alexander',
    'Rachel',
    'Raymond',
    'Catherine',
    'Patrick',
    'Carolyn',
    'Jack',
    'Janet',
    'Dennis',
    'Ruth',
    'Jerry',
    'Maria',
    'Tyler',
    'Heather',
    'Aaron',
    'Diane',
    'Jose',
    'Virginia',
    'Adam',
    'Julie',
    'Henry',
    'Joyce',
    'Nathan',
    'Victoria',
    'Douglas',
    'Olivia',
    'Zachary',
    'Kelly',
    'Peter',
    'Christina',
    'Kyle',
    'Lauren',
    'Walter',
    'Joan',
    'Ethan',
    'Evelyn',
    'Jeremy',
    'Judith',
    'Harold',
    'Megan',
    'Keith',
    'Cheryl',
    'Christian',
    'Andrea',
    'Roger',
    'Hannah',
    'Noah',
    'Martha',
    'Gerald',
    'Jacqueline',
    'Carl',
    'Frances',
    'Terry',
    'Gloria',
    'Sean',
    'Ann',
    'Austin',
    'Teresa',
    'Arthur',
    'Kathryn',
    'Lawrence',
    'Sara',
    'Jesse',
    'Janice',
    'Dylan',
    'Jean',
    'Bryan',
    'Alice',
    'Joe',
    'Madison',
    'Jordan',
    'Doris',
    'Billy',
    'Abigail',
    'Bruce',
    'Julia',
    'Albert',
    'Judy',
    'Willie',
    'Grace',
    'Gabriel',
    'Denise',
    'Logan',
    'Amber',
    'Alan',
    'Marilyn',
    'Juan',
    'Beverly',
    'Wayne',
    'Danielle',
    'Roy',
    'Theresa',
    'Ralph',
    'Sophia',
    'Randy',
    'Marie',
    'Eugene',
    'Diana',
    'Vincent',
    'Brittany',
    'Russell',
    'Natalie',
    'Elijah',
    'Isabella',
    'Louis',
    'Charlotte',
    'Bobby',
    'Rose',
    'Philip',
    'Alexis',
    'Johnny',
    'Kayla',
  ],
  germanNames: [
    'Alfred',
    'Albrecht',
    'Boris',
    'Bruno',
    'Uli',
    'Ulf',
    'Olaf',
    'Duli',
    'Fanny',
    'Frieda',
    'Franziska',
    'Hans',
    'Hugo',
    'Karl',
    'Ludwig',
    'Granini',
    'Gude',
    'Gundula',
    'Heidi',
    'Hibbel',
    'Maxi',
    'Mini',
    'Meise',
    'Finniboy',
    'Thomas',
    'Baldwin',
    'Franky',
    'Gunther',
    'Hamlet',
    'Kurt',
    'Leon',
    'Louisa',
    'Oskar',
    'Otis',
    'Paul',
    'Sigmund',
    'Salami',
    'Stein',
    'Bob',
  ],
  globalNames: [
    'Oliver',
    'Charlotte',
    'Noah',
    'Olivia',
    'Jack',
    'Amelia',
    'William',
    'Isla',
    'Leo',
    'Mia',
    'Lucas',
    'Ava',
    'Thomas',
    'Grace',
    'Henry',
    'Willow',
    'Charlie',
    'Harper',
    'James',
    'Chloe',
    'Ali',
    'Zahra',
    'Elchin',
    'Zeynab',
    'Vugar',
    'Maryam',
    'Anar',
    'Malak',
    'Elnur',
    'Aylin',
    'Samir',
    'Fatima',
    'Eishan',
    'Nuray',
    'Rashad',
    'Nilay',
    'Ilgar',
    'Inji',
    'Vusal',
    'Daniz',
    'Miguel',
    'Helena',
    'Arthur',
    'Alice',
    'Heitor',
    'Laura',
    'Theo',
    'Valentina',
    'Davi',
    'Heloisa',
    'Gabriel',
    'Sophia',
    'Gael',
    'Lorena',
    'Bernardo',
    'Manuela',
    'Samuel',
    'Cecilia',
    'Pedro',
    'Julia',
    'Mateo',
    'Agustina',
    'Agustin',
    'Isabella',
    'Tomas',
    'Sofia',
    'Santiago',
    'Emilia',
    'Lucas',
    'Isidora',
    'Benjamin',
    'Emma',
    'Vicente',
    'Maite',
    'Maximiliano',
    'Josefa',
    'Joaquin',
    'Florencia',
    'Matias',
    'Maria',
    'Luka',
    'Mia',
    'David',
    'Lucija',
    'Ivan',
    'Sara',
    'Jakov',
    'Ana',
    'Marko',
    'Ema',
    'Petar',
    'Petra',
    'Filip',
    'Lana',
    'Matej',
    'Nika',
    'Mateo',
    'Marta',
    'Leon',
    'Elena',
    'William',
    'Ida',
    'Noah',
    'Emma',
    'Oscar',
    'Alma',
    'Lukas',
    'Ella',
    'Victor',
    'Sofia',
    'Malthe',
    'Freja',
    'Oliver',
    'Josefine',
    'Alfred',
    'Clara',
    'Carl',
    'Anna',
    'Valdemar',
    'Karla',
    'Alois',
    'Elea',
    'Matheo',
    'Lya',
    'Raphael',
    'Elyna',
    'Elliot',
    'Anna',
    'Liam',
    'Lili',
    'Mylan',
    'Noemie',
    'Gabriel',
    'Sophia',
    'Theo',
    'Julia',
    'Lucas',
    'Louise',
    'Mael',
    'Ella',
    'Georgios',
    'Maria',
    'Ioannis',
    'Eleni',
    'Konstantinos',
    'Aikaterini',
    'Dimitrios',
    'Vasiliki',
    'Nikolaos',
    'Sophia',
    'Panagiotis',
    'Angeliki',
    'Vasileios',
    'Georgia',
    'Christos',
    'Dimitra',
    'Athanasios',
    'Kostantina',
    'Michail',
    'Paraskevi',
    'Muhammad',
    'Maryam',
    'Adhvik',
    'Saanvi',
    'Shivansh',
    'Zara',
    'Aarav',
    'Inaya',
    'Ayaan',
    'Aarya',
    'Atharv',
    'Pari',
    'Sai',
    'Ira',
    'Aryan',
    'Fatima',
    'Ishaan',
    'Armayra',
    'Krishna',
    'Kiara',
    'Ori',
    'Tamar',
    'David',
    'Avigail',
    'Ariel',
    'Adele',
    'Noam',
    'Noa',
    'Eitan',
    'Shira',
    'Yosef',
    'Talia',
    'Itai',
    'Yael',
    'Daniel',
    'Sarah',
    'Yonatan',
    'Lia',
    'Lavi',
    'Esther',
    'Haruto',
    'Honoka',
    'Minato',
    'Akari',
    'Haruki',
    'Himari',
    'Sota',
    'Mei',
    'Yuto',
    'Ema',
    'Riku',
    'Yui',
    'Yuito',
    'Mio',
    'Aoto',
    'Ichika',
    'Aoi',
    'Aoi',
    'Hinata',
    'Tsumugi',
    'Santiago',
    'Ximena',
    'Mateo',
    'Valentina',
    'Matias',
    'Maria Fernanda',
    'Diego',
    'Sofia',
    'Sebastian',
    'Maria Jose',
    'Nicolas',
    'Martina',
    'Miguel Angel',
    'Zoe',
    'Alejandro',
    'Mia',
    'Samuel',
    'Dulce Maria',
    'Daniel',
    'Maria Guadalupe',
    'Oliver',
    'Isla',
    'Noah',
    'Amelia',
    'Leo',
    'Olivia',
    'Lucas',
    'Willow',
    'George',
    'Harper',
    'Charlie',
    'Ava',
    'William',
    'Lily',
    'Thomas',
    'Sophie',
    'Hunter',
    'Ella',
    'Nathaniel',
    'Angel',
    'Gabriel',
    'Althea',
    'James',
    'Princess',
    'Francis',
    'Ashley',
    'Joshua',
    'Samantha',
    'Jacon',
    'Sophia',
    'Angelo',
    'Andrea',
    'Daniel',
    'Angela',
    'Alexander',
    'Janine',
    'John Mark',
    'Sofia',
    'Joao',
    'Maria',
    'Martim',
    'Leonor',
    'Rodrigo',
    'Matilde',
    'Santiago',
    'Beatriz',
    'Francisco',
    'Carolina',
    'Afonso',
    'Mariana',
    'Tomas',
    'Ana',
    'Miguel',
    'Ines',
    'Guilherme',
    'Margarida',
    'Gabriel',
    'Sofia',
    'Artyom',
    'Sofia',
    'Aleksandr',
    'Anastasia',
    'Roman',
    'Victoria',
    'Yevgeny',
    'Ksenia',
    'Ivan',
    'Arina',
    'Maksim',
    'Yelizaveta',
    'Denis',
    'Adelina',
    'Alexey',
    'Irina',
    'Dmitry',
    'Yelena',
    'Danyl',
    'Polina',
    'Jack',
    'Isla',
    'James',
    'Emily',
    'Oliver',
    'Ava',
    'Rory',
    'Ella',
    'Finlay',
    'Amelia',
    'Lucas',
    'Sophia',
    'Alfie',
    'Evie',
    'Lewis',
    'Maisie',
    'Brodie',
    'Mia',
    'Mason',
    'Aria',
    'Liam',
    'Olivia',
    'Lucas',
    'Emma',
    'Oliver',
    'Amelia',
    'Benjamin',
    'Charlotte',
    'Logan',
    'Chloe',
    'Ethan',
    'Sophia',
    'Noah',
    'Ava',
    'William',
    'Isla',
    'James',
    'Emily',
    'Leo',
    'Abigail',
    'Lucas',
    'Maria',
    'Louis',
    'Marie',
    'Noah',
    'Dominique',
    'Nathan',
    'Martine',
    'Adam',
    'Nathalie',
    'Arthur',
    'Anne',
    'Mohamed',
    'Rita',
    'Kobe',
    'Nicole',
    'Victor',
    'Anna',
    'Mathis',
    'Christiane',
    'An',
    'Ai',
    'Bo',
    'Bi',
    'Cheng',
    'Cai',
    'De',
    'Dan',
    'Dong',
    'Fang',
    'Feng',
    'Hong',
    'Gang',
    'Hui',
    'Guo',
    'Juan',
    'Hui',
    'Lan',
    'Jian',
    'Li',
    'Jakub',
    'Eliska',
    'Jan',
    'Tereza',
    'Tomas',
    'Anna',
    'David',
    'Adela',
    'Adam',
    'Natalie',
    'Matyas',
    'Sofie',
    'Filip',
    'Kristyna',
    'Vojtech',
    'Karolina',
    'Ondrej',
    'Viktorie',
    'Lukas',
    'Barbora',
    'Emil',
    'Aino',
    'Liam',
    'Aada',
    'William',
    'Sofia',
    'Oliver',
    'Eevi',
    'Edvin',
    'Olivia',
    'Max',
    'Lilja',
    'Hugo',
    'Helmi',
    'Benjamin',
    'Ellen',
    'Elias',
    'Emilia',
    'Leo',
    'Ella',
    'Noah',
    'Emma',
    'Leon',
    'Emilia',
    'Matteo',
    'Ella',
    'Paul',
    'Mia',
    'Felix',
    'Marie',
    'Theo',
    'Lina',
    'Elias',
    'Lea',
    'Ben',
    'Mila',
    'Emil',
    'Sophia',
    'Finn',
    'Hannah',
    'Aron',
    'Margret',
    'Alexander',
    'Anna',
    'Viktor',
    'Victor',
    'Emma',
    'Kristjan',
    'Christian',
    'Isabella',
    'Jon',
    'Eva',
    'Guomundur',
    'Kristofer',
    'Hekla',
    'Kristin',
    'Viktoria',
    'Gunnar',
    'Olav',
    'Emilia',
    'Katrin',
    'Jack',
    'Grace',
    'James',
    'Fiadh',
    'Noah',
    'Emily',
    'Daniel',
    'Sophie',
    'Conor',
    'Ava',
    'Finn',
    'Amelia',
    'Liam',
    'Ella',
    'Fionn',
    'Hannah',
    'Harry',
    'Lucy',
    'Charlie',
    'Mia',
    'Leonardo',
    'Sofia',
    'Francesco',
    'Giulia',
    'Alessandro',
    'Aurora',
    'Lorenzo',
    'Alice',
    'Mattia',
    'Ginevra',
    'Andrea',
    'Emma',
    'Gabriele',
    'Giorgia',
    'Riccardo',
    'Greta',
    'Tommaso',
    'Beatrice',
    'Edoardo',
    'Anna',
    'Gabriel',
    'Emma',
    'Leo',
    'Lara',
    'Luca',
    'Zoe',
    'Noah',
    'Amy',
    'David',
    'Sarah',
    'Tom',
    'Charlotte',
    'Ben',
    'Emily',
    'Daan',
    'Anna',
    'Emma',
    'Tess',
    'Noah',
    'Sophie',
    'Sem',
    'Julie',
    'Lucas',
    'Zoe',
    'Jesse',
    'Evi',
    'Finn',
    'Mila',
    'Milan',
    'Sara',
    'Max',
    'Eva',
    'Levi',
    'Fenna',
    'Luuk',
    'Lotte',
    'Jakob',
    'Nora',
    'Emil',
    'Emma',
    'Noah',
    'Ella',
    'Oliver',
    'Maia',
    'Philip',
    'Olivia',
    'William',
    'Emilie',
    'Lucas',
    'Sofie',
    'Liam',
    'Leah',
    'Henrik',
    'Sofia',
    'Oskar',
    'Ingrid',
    'Antoni',
    'Zuzanna',
    'Jakub',
    'Julia',
    'Jan',
    'Lena',
    'Szymon',
    'Maja',
    'Aleksander',
    'Hannah',
    'Franciszek',
    'Zofia',
    'Filip',
    'Amelia',
    'Mikolaj',
    'Alicja',
    'Wojciech',
    'Aleksandra',
    'Kacper',
    'Natalia',
    'Andrei',
    'Maria',
    'David',
    'Ioana',
    'Alexandru',
    'Elena',
    'Stefan',
    'Sofia',
    'Gabriel',
    'Andreea',
    'Cristian',
    'Alexandra',
    'Stefan',
    'Antonia',
    'Luca',
    'Daria',
    'Ionut',
    'Ana',
    'Darius',
    'Gabriel',
    'Mohammad',
    'Sara',
    'Fahd',
    'Latifa',
    'Abdullah',
    'Nora',
    'Abdulrahman',
    'Hessa',
    'Turki',
    'Maysoun',
    'Bandar',
    'Maha',
    'Omar',
    'Nouf',
    'Ali',
    'Noor',
    'Reema',
    'Alanoud',
    'Enzokuhle',
    'Enzokuhle',
    'Lethabo',
    'Melokuhle',
    'Melokuhle',
    'Amahle',
    'Lubanzi',
    'Okuhle',
    'Junior',
    'Lethabo',
    'Siyabonga',
    'Omphile',
    'Bokamoso',
    'Lesedi',
    'Omphile',
    'Rethabile',
    'Amogelang',
    'Amogelang',
    'Thato',
    'Onthatile',
    'Lucas',
    'Alice',
    'Liam',
    'Olivia',
    'William',
    'Astrid',
    'Elias',
    'Maja',
    'Noah',
    'Vera',
    'Hugo',
    'Ebba',
    'Oliver',
    'Ella',
    'Oscar',
    'Wilma',
    'Adam',
    'Alma',
    'Matteo',
    'Lilly',
    'Yusuf',
    'Zeynep',
    'Mirac',
    'Elif',
    'Eymen',
    'Defne',
    'Omer Asaf',
    'Asel',
    'Omer',
    'Azra',
    'Mustafa',
    'Eylul',
    'Kerem',
    'Nehir',
    'Alparslan',
    'Eslem',
    'Hamza',
    'Asya',
    'Ali Asaf',
    'Ecrin',
  ],
  general: ['scorp', 'scrop', 'guy', 'fella', 'cutie', 'boy', 'girl', 'jerk', 'sting'],
  smokers: [
    'smoke',
    'smokey',
    'smoker',
    'ash',
    'burn',
    'hot',
    'cloud',
    'cough',
    'marlboro',
    'camel',
    'parliament',
    'menthol',
    'clove',
    'spliff',
    'filter',
    'roll',
    'puff',
    'hit',
    'pyro',
    'burno',
  ],
  halo: ['divine', 'revered', 'worshipper', 'holy', 'pious', 'god', 'religious', 'crown', 'ring', 'halo', 'sacred'],
  ballers: [
    'baller',
    'ball',
    'balsy',
    'boxer',
    'tripod',
    'bag',
    'round',
    'circle',
    'maraca',
    'maracas',
    'cue',
    'shark',
    'basketball',
    'football',
    'soccer',
    'tennis',
    'catcher',
    'thrower',
    'squash',
    'racket',
    'federer',
    'nadal',
    'djokovic',
  ],
  druglords: [
    'dealer',
    'plug',
    'drug',
    'druggie',
    'stoner',
    'needle',
    'addict',
    'addiction',
    'user',
    'pill',
    'serum',
    'hormone',
    'high',
    'crazy',
    'bender',
    'intoxicated',
    'detox',
    'toxic',
    'euphoric',
    'whippet',
    'acid',
    'al capone',
    'scarface',
    'coke',
    'cokehead',
    'weed',
    'cannabis',
    'psychonaut',
    'molly',
    'benzo',
  ],
  monos: [
    'color',
    'palette',
    'monochrome',
    'el monocromo',
    'paint',
    'painter',
    'pigment',
    'pig',
    'art',
    'artist',
    'focused',
    'solo',
    'mono',
    'boring',
    'simple',
    'stylish',
    'plain',
    'muted',
    'embossed',
    'boss',
    'stamp',
    '49er',
  ],
  striped: ['stripe', 'stripey', 'zebra', 'bee', 'rib', 'tiger'],
  claws_unique: ['unbalanced', 'lefty', 'righty', 'left', 'right', 'ambi', 'handy', 'hands', 'handsy', 'ambidextrous'],
  claw_big: [
    'big',
    'huge',
    'massive',
    'gigantic',
    'macro',
    'giga',
    'mega',
    'tera',
    'tiny',
    'mini',
    'strong',
    'heavy',
    'tank',
    'body',
    'power',
    'wow',
    'chad',
  ],
  claw_missing: ['lacking', 'gone', 'invisible', 'imaginary', 'fake', 'faux', 'missing'],
  boxers: [
    'ali',
    'mayweather',
    'tyson',
    'rocky',
    'socker',
    'smacker',
    'ball',
    'boxer',
    'puncher',
    'punching bag',
    'bowler',
    'pin',
    'bowl',
    'alley',
    'lawrence',
    'okolie',
    'uppercut',
    'jab',
    'strike',
    'spare',
    'hook',
  ],
  mushies: [
    'shroom',
    'shiitake',
    'mushroom',
    'fungi',
    'fungus',
    'mycologist',
    'growth',
    'truffle',
    'poison',
    'toxic',
    'chanterelle',
    'enoki',
    'morel',
    'puffball',
    'cloud',
    'oyster',
    'magic',
    'lobster',
    'baby bell',
    'fairy',
    'champignon',
    'musho',
  ],
  edwards: [
    'hair',
    'stylist',
    'curly',
    'strait',
    'wavy',
    'kinky',
    'dice',
    'dicer',
    'dicey',
    'chop',
    'chopper',
    'choppy',
    'slice',
    'slicer',
    'slicy',
    'cutter',
    'cut',
    'scissor',
    'scissors',
    'edward',
    'scissorhands',
    'chip chop',
    'snip snip',
  ],
  evil_eyes: [
    'crossed',
    'eyes',
    'evil',
    'monster',
    'anger',
    'stern',
    'scared',
    'angry',
    'scary',
    'face',
    'stare',
    'medusa',
    'gargoyle',
    'mirror',
    'doom',
    'glare',
  ],
  bloody_tails: [
    'bloody',
    'blood',
    'bleeding',
    'wounded',
    'wound',
    'injured',
    'injury',
    'fire',
    'dragon',
    'burning',
    'torch',
  ],
  white_outlines: [
    'ghost',
    'casper',
    'white',
    'snowy',
    'transparent',
    'phantom',
    'ghoul',
    'ghastly',
    'haunt',
    'boo',
    'haunt',
  ],
  blind: ['blind', 'vision', 'eye', 'seeing', 'seer', 'nearsighted', 'braille', 'glasses', 'monk', 'window', 'cane'],
  syringe_tails: [
    'pokey',
    'poke',
    'syringe',
    'sharp',
    'needle',
    'jab',
    'prick',
    'drug',
    'heroin',
    'aids',
    'virus',
    'covid',
    'vaccine',
    'antivaxxer',
  ],
  fat_tails: ['dino', 'fatty', 'biggie', 'big butt', 'dinosaur', 'beaver'],
  ball_tails: [
    'ball and chain',
    'ball butt',
    'chomp chomp',
    'ampharos',
    'mace',
    'bludgeon',
    'blunt',
    'gada',
    'flail',
    'spice',
  ],
  missing_tails: ['tail', 'no butt', 'gone', 'stump', 'stumpy', 'tailbone', 'missing'],
  stubby_legs: [
    'short',
    'stub',
    'stubby',
    'slow',
    'stump',
    'amputee',
    'dwarf',
    'never skips leg day',
    'leg day',
    'squatter',
    'slowpoke',
    'hard to knock over',
    'stomp',
    'stompy',
  ],
  insect_legs: [
    'insect',
    'buzz',
    'fly',
    'mosquito',
    'bzz',
    'bee',
    'cricket',
    'roach',
    'bug',
    'bugged',
    'art',
    'ant',
    'louse',
    'lice',
    'maggot',
    'infestation',
  ],
  skinny_legs: ['svelte', 'skinny', 'petite', "lil'", 'tiptoe'],
  fat_legs: [],
  dinosaur: [
    'dinosaur',
    'extinction',
    'extinct',
    'fossil',
    'prehistoric',
    'fossils',
    'herbivore',
    'excavate',
    'herbivorous',
    'omnivore',
    'period',
    'omnivorous',
    'Iguanodon',
    'Parasaurolophus',
    'Ornithomimus',
    'Ceratosaurus',
    'Giganotosaurus',
    'Diplodocus',
    'Albertosaurus',
    'Brachiosaurus',
    'Allosaurus',
    'predator',
    'Triassic',
    'prey',
    'carnivore',
    'warm-blooded',
    'carnivorous',
    'Spinosaurus',
    'Stegosaurus',
    'Triceratops',
    'T-Rex',
    'Velociraptors',
    'Tiarajudens',
    'biped',
    'meteorite',
    'Jurassic Period',
    'erosion',
    'skeleton',
    'predict',
    'trace-fossiles',
    'theropod',
    'cast',
    'Palaeontology',
    'hypothesis',
    'Palaeontologist',
    'Cretaceous Period',
    'Saurischia',
    'Suchomimus',
    'Ornithischian',
    'Cretaceous',
    'cold-blooded',
    'frill',
    'Geologist',
    'Mesozoic',
  ],
  insects: [
    'Active',
    'Affect',
    'Antennae',
    'Ants',
    'Aphid',
    'Appendage',
    'Aquatic',
    'Arthropod',
    'Attractive',
    'Balance',
    'Bees',
    'Beetles',
    'Bite',
    'Bug',
    'Butterfly',
    'Carrier',
    'Category',
    'Cercus',
    'Cicadas',
    'Classification',
    'Climate',
    'Colony',
    'Compound eye',
    'Damage',
    'Development',
    'Earth',
    'Embryonic',
    'Entomologist',
    'Environment',
    'Fleas',
    'Flies',
    'Forewing',
    'Frequency',
    'Fumigation',
    'Geography',
    'Gnat',
    'Habitat',
    'Harmful',
    'Hind-wing',
    'Immunity',
    'Indefatigable',
    'Indigenous',
    'Industrious',
    'Inoculate',
    'Insatiable',
    'Insecticide',
    'Instinct',
    'Invertebrate',
    'Investigate',
    'Jointed',
    'Jungle',
    'Katydid',
    'Larvae',
    'Lice',
    'Mandible',
    'Metamorphosis',
    'Miniscule',
    'Motion',
    'Movement',
    'Naiad',
    'Nature',
    'Nemesis',
    'Nocturnal',
    'Nymph',
    'Organ',
    'Ovipositor',
    'Paired',
    'Pests',
    'Phyla',
    'Plants',
    'Poisonous',
    'Pollinate',
    'Predator',
    'Prey',
    'Proboscis',
    'Propagation',
    'quick',
    'Quiet',
    'Regional',
    'Repellent',
    'Resilient',
    'Resistant',
    'Segmented',
    'Seize',
    'Shed',
    'Specie',
    'Speed',
    'Spiracle',
    'Stealth',
    'Strength',
    'Subterranean',
    'Swarm',
    'Terrestrial',
    'Thorax',
    'Thrive',
    'Transmit',
    'Trap',
    'Trepidation',
    'Type',
    'Ubiquitous',
    'Variety',
    'Visibility',
    'Vital',
    'Vulnerable',
    'Walking',
    'stick',
    'Wallow',
    'Wildlife',
    'Wings',
    'Wood',
    'wasp',
    'Yellow',
    'jacket',
    'Zone',
  ],
  art: [
    'Ability',
    'Absorbing',
    'Abstract',
    'Acclaimed',
    'Acquisition',
    'Acrylic',
    'Adaptation',
    'Adept',
    'Aesthetic',
    'Aficionado',
    'Analogous',
    'Angular',
    'Appreciation',
    'Arrangement',
    'Array',
    'Artistic',
    'Artlessness',
    'Assemblage',
    'Assemble',
    'Assist',
    'Assistance',
    'Asymmetry',
    'Attempt',
    'Attraction',
    'Audience',
    'Audience',
    'Authentic',
    'Avid',
    'Award-winning',
    'Axis',
    'Background',
    'Balance',
    'Baroque',
    'Beauty',
    'Beholder',
    'Bias',
    'Blur',
    'Bravado',
    'Bravo',
    'Brushwork',
    'Caliber',
    'Canvas',
    'Captivate',
    'Capture',
    'Carve',
    'Caution',
    'Celebrity',
    'Ceramics',
    'Chalk',
    'Charcoal',
    'Charisma',
    'Clamor',
    'Classic',
    'Clay',
    'Collaboration',
    'Collage',
    'Collection',
    'Color',
    'Commanding',
    'Commitment',
    'Communication',
    'Competition',
    'Complementary',
    'Component',
    'Composition',
    'Concave',
    'Concentration',
    'Connoisseur',
    'Contour',
    'Contract',
    'Contrast',
    'Contribution',
    'Converge',
    'Convex',
    'Cool color',
    'Coordination',
    'Crafts',
    'Creativity',
    'Crescendo',
    'Crowd-pleasing',
    'Culture',
    'Curator',
    'Curve',
    'Dabble',
    'Dazzle',
    'Debut',
    'Decor',
    'Decorative',
    'Demonstration',
    'Depth',
    'Design',
    'Device',
    'Diagonal',
    'Diffuse',
    'Digital image',
    'Direction',
    'Disorganized',
    'Display',
    'Distraction',
    'Diverse',
    'Diversity',
    'Dramatic',
    'Drive',
    'Dynamic',
    'Effect',
    'Element',
    'Eloquence',
    'Emotion',
    'Endurance',
    'Energy',
    'Ensemble',
    'Entertainment',
    'Essence',
    'Euphoric',
    'Evaluation',
    'Exaggeration',
    'Exalt',
    'Execution',
    'Exemplary',
    'Exhibition',
    'Exposure',
    'Expression',
    'Expressive',
    'Exquisite',
    'Extraordinary',
    'Facade',
    'Facets',
    'Facilitate',
    'Falter',
    'Fascination',
    'Features',
    'Finalist',
    'Flat',
    'Flexible',
    'Flourish',
    'Fluid',
    'Fluorescent',
    'Focus',
    'Foreground',
    'Form',
    'Format',
    'Forte',
    'Framework',
    'Frenzy',
    'Fuzz',
    'Gauge',
    'Genius',
    'Genre',
    'Geometric',
    'Gesture',
    'Glare',
    'Glaze',
    'Gloss',
    'Graceful',
    'Graphic',
    'Harmony',
    'Hatching',
    'Hiatus',
    'Highlight',
    'Hone',
    'Horizon line',
    'Hue',
    'Humanities',
    'Hype',
    'Iconic',
    'Idealization',
    'Illumination',
    'Illustration',
    'Illustrious',
    'Image',
    'Imagery',
    'Imagination',
    'Impact',
    'Impression',
    'Impressionist',
    'Impressive',
    'Impromptu',
    'Inclination',
    'Inconsistent',
    'Individual',
    'Influence',
    'Infusion',
    'Innovative',
    'Insight',
    'Inspirational',
    'Instrumental',
    'Integrity',
    'Intensity',
    'Intent',
    'International',
    'Interpretation',
    'Inventive',
    'Jagged',
    'Jargon',
    'Judge',
    'Judgment',
    'Juxtaposition',
    'Keen',
    'Key',
    'Landscape',
    'Legendary',
    'Light source',
    'Lighting',
    'Line',
    'Lively',
    'Luminous',
    'Luster',
    'Lyrical',
    'Magic',
    'Major',
    'Manifestation',
    'Masterwork',
    'Maximize',
    'Meager',
    'Measure',
    'Media',
    'Medium',
    'Memorable',
    'Minimize',
    'Minor',
    'Mixed',
    'Momentum',
    'Mood',
    'Motion',
    'Motivate',
    'Motivation',
    'Movement',
    'Movies',
    'Muse',
    'Neutral',
    'Niche',
    'Nimble',
    'Nostalgia',
    'Notation',
    'Nuance',
    'Ocher',
    'Oomph',
    'Opportunity',
    'Opt',
    'Optimal',
    'Ornate',
    'Outline',
    'Outstanding',
    'Oval',
    'Overlap',
    'Overture',
    'Painting',
    'Palette',
    'Parallel',
    'Participation',
    'Passionate',
    'Pastel',
    'Pattern',
    'Perception',
    'Perpendicular',
    'Persistence',
    'Perspective',
    'Perspiration',
    'Phenomenal',
    'Pigment',
    'Pinnacle',
    'Placement',
    'Popular',
    'Portrait',
    'Portrayal',
    'Pose',
    'Position',
    'Potent',
    'Pottery',
    'Powerful',
    'Practice',
    'Prelude',
    'Premiere',
    'Presence',
    'Pressure',
    'Primary',
    'Professional',
    'Profile',
    'Project',
    'Promising',
    'Proportion',
    'Provocative',
    'Quality',
    'Quantity',
    'Quick',
    'Quintessential',
    'Quirk',
    'Realistic',
    'Recede',
    'Recognition',
    'Refine',
    'Repetition',
    'Reputation',
    'Resilient',
    'Resonance',
    'Response',
    'Restraint',
    'Retrospective',
    'Revival',
    'Rhythm',
    'Scale',
    'Scenery',
    'Scheme',
    'Sculpt',
    'Sculpture',
    'Secondary',
    'Shade',
    'Shadow',
    'Shallowness',
    'Shapes',
    'Sharp',
    'Sketch',
    'Skill',
    'Solo',
    'Soulful',
    'Space',
    'Spectacular',
    'Spectrum',
    'Speculation',
    'Spin',
    'Spirit',
    'Spontaneity',
    'Spotlight',
    'Stamina',
    'Star',
    'Status',
    'Still life',
    'Stipple',
    'Straight',
    'Streak',
    'Strength',
    'Stretch',
    'Stunning',
    'Style',
    'Sublime',
    'Subtle',
    'Symbolic',
    'Symmetry',
    'Talent',
    'Technical',
    'Technicolor',
    'Technique',
    'Tertiary',
    'Texture',
    'Tint',
    'Tone',
    'Training',
    'Transform',
    'Transformation',
    'Transmission',
    'Ultimate',
    'Unbelievable',
    'Unconventional',
    'Unique',
    'Unity',
    'Unprecedented',
    'Unusual',
    'Value',
    'Vanguard',
    'Vanishing point',
    'Variation',
    'Variety',
    'Veer',
    'Version',
    'Vertical',
    'Vibrant',
    'Viewpoint',
    'Visibility',
    'Vision',
    'Visual',
    'Vital',
    'Volatile',
    'Warm',
    'Watercolor',
    'Whirl',
    'Zeal',
    'Zest',
    'Zip',
  ],
  nouns: [
    'people',
    'history',
    'way',
    'art',
    'world',
    'information',
    'map',
    'two',
    'family',
    'government',
    'health',
    'system',
    'computer',
    'meat',
    'year',
    'thanks',
    'music',
    'person',
    'reading',
    'method',
    'data',
    'food',
    'understanding',
    'theory',
    'law',
    'bird',
    'literature',
    'problem',
    'software',
    'control',
    'knowledge',
    'power',
    'ability',
    'economics',
    'love',
    'internet',
    'television',
    'science',
    'library',
    'nature',
    'fact',
    'product',
    'idea',
    'temperature',
    'investment',
    'area',
    'society',
    'activity',
    'story',
    'industry',
    'media',
    'thing',
    'oven',
    'community',
    'definition',
    'safety',
    'quality',
    'development',
    'language',
    'management',
    'player',
    'variety',
    'video',
    'week',
    'security',
    'country',
    'exam',
    'movie',
    'organization',
    'equipment',
    'physics',
    'analysis',
    'policy',
    'series',
    'thought',
    'basis',
    'boyfriend',
    'direction',
    'strategy',
    'technology',
    'army',
    'camera',
    'freedom',
    'paper',
    'environment',
    'child',
    'instance',
    'month',
    'truth',
    'marketing',
    'university',
    'writing',
    'article',
    'department',
    'difference',
    'goal',
    'news',
    'audience',
    'fishing',
    'growth',
    'income',
    'marriage',
    'user',
    'combination',
    'failure',
    'meaning',
    'medicine',
    'philosophy',
    'teacher',
    'communication',
    'night',
    'chemistry',
    'disease',
    'disk',
    'energy',
    'nation',
    'road',
    'role',
    'soup',
    'advertising',
    'location',
    'success',
    'addition',
    'apartment',
    'education',
    'math',
    'moment',
    'painting',
    'politics',
    'attention',
    'decision',
    'event',
    'property',
    'shopping',
    'student',
    'wood',
    'competition',
    'distribution',
    'entertainment',
    'office',
    'population',
    'president',
    'unit',
    'category',
    'cigarette',
    'context',
    'introduction',
    'opportunity',
    'performance',
    'driver',
    'flight',
    'length',
    'magazine',
    'newspaper',
    'relationship',
    'teaching',
    'cell',
    'dealer',
    'finding',
    'lake',
    'member',
    'message',
    'phone',
    'scene',
    'appearance',
    'association',
    'concept',
    'customer',
    'death',
    'discussion',
    'housing',
    'inflation',
    'insurance',
    'mood',
    'woman',
    'advice',
    'blood',
    'effort',
    'expression',
    'importance',
    'opinion',
    'payment',
    'reality',
    'responsibility',
    'situation',
    'skill',
    'statement',
    'wealth',
    'application',
    'city',
    'county',
    'depth',
    'estate',
    'foundation',
    'grandmother',
    'heart',
    'perspective',
    'photo',
    'recipe',
    'studio',
    'topic',
    'collection',
    'depression',
    'imagination',
    'passion',
    'percentage',
    'resource',
    'setting',
    'ad',
    'agency',
    'college',
    'connection',
    'criticism',
    'debt',
    'description',
    'memory',
    'patience',
    'secretary',
    'solution',
    'administration',
    'aspect',
    'attitude',
    'director',
    'personality',
    'psychology',
    'recommendation',
    'response',
    'selection',
    'storage',
    'version',
    'alcohol',
    'argument',
    'complaint',
    'contract',
    'emphasis',
    'highway',
    'loss',
    'membership',
    'possession',
    'preparation',
    'steak',
    'union',
    'agreement',
    'cancer',
    'currency',
    'employment',
    'engineering',
    'entry',
    'interaction',
    'mixture',
    'preference',
    'region',
    'republic',
    'tradition',
    'virus',
    'actor',
    'classroom',
    'delivery',
    'device',
    'difficulty',
    'drama',
    'election',
    'engine',
    'football',
    'guidance',
    'hotel',
    'owner',
    'priority',
    'protection',
    'suggestion',
    'tension',
    'variation',
    'anxiety',
    'atmosphere',
    'awareness',
    'bath',
    'bread',
    'candidate',
    'climate',
    'comparison',
    'confusion',
    'construction',
    'elevator',
    'emotion',
    'employee',
    'employer',
    'guest',
    'height',
    'leadership',
    'mall',
    'manager',
    'operation',
    'recording',
    'sample',
    'transportation',
    'charity',
    'cousin',
    'disaster',
    'editor',
    'efficiency',
    'excitement',
    'extent',
    'feedback',
    'guitar',
    'homework',
    'leader',
    'mom',
    'outcome',
    'permission',
    'presentation',
    'promotion',
    'reflection',
    'refrigerator',
    'resolution',
    'revenue',
    'session',
    'singer',
    'tennis',
    'basket',
    'bonus',
    'cabinet',
    'childhood',
    'church',
    'clothes',
    'coffee',
    'dinner',
    'drawing',
    'hair',
    'hearing',
    'initiative',
    'judgment',
    'lab',
    'measurement',
    'mode',
    'mud',
    'orange',
    'poetry',
    'police',
    'possibility',
    'procedure',
    'queen',
    'ratio',
    'relation',
    'restaurant',
    'satisfaction',
    'sector',
    'signature',
    'significance',
    'song',
    'tooth',
    'town',
    'vehicle',
    'volume',
    'wife',
    'accident',
    'airport',
    'appointment',
    'arrival',
    'assumption',
    'baseball',
    'chapter',
    'committee',
    'conversation',
    'database',
    'enthusiasm',
    'error',
    'explanation',
    'farmer',
    'gate',
    'girl',
    'hall',
    'historian',
    'hospital',
    'injury',
    'instruction',
    'maintenance',
    'manufacturer',
    'meal',
    'perception',
    'pie',
    'poem',
    'presence',
    'proposal',
    'reception',
    'replacement',
    'revolution',
    'river',
    'son',
    'speech',
    'tea',
    'village',
    'warning',
    'winner',
    'worker',
    'writer',
    'assistance',
    'breath',
    'buyer',
    'chest',
    'chocolate',
    'conclusion',
    'contribution',
    'cookie',
    'courage',
    'dad',
    'desk',
    'drawer',
    'establishment',
    'examination',
    'garbage',
    'grocery',
    'honey',
    'impression',
    'improvement',
    'independence',
    'insect',
    'inspection',
    'inspector',
    'king',
    'ladder',
    'menu',
    'penalty',
    'piano',
    'potato',
    'profession',
    'professor',
    'quantity',
    'reaction',
    'requirement',
    'salad',
    'sister',
    'supermarket',
    'tongue',
    'weakness',
    'wedding',
    'affair',
    'ambition',
    'analyst',
    'apple',
    'assignment',
    'assistant',
    'bathroom',
    'bedroom',
    'beer',
    'birthday',
    'celebration',
    'championship',
    'cheek',
    'client',
    'consequence',
    'departure',
    'diamond',
    'dirt',
    'ear',
    'fortune',
    'friendship',
    'funeral',
    'gene',
    'girlfriend',
    'hat',
    'indication',
    'intention',
    'lady',
    'midnight',
    'negotiation',
    'obligation',
    'passenger',
    'pizza',
    'platform',
    'poet',
    'pollution',
    'recognition',
    'reputation',
    'shirt',
    'sir',
    'speaker',
    'stranger',
    'surgery',
    'sympathy',
    'tale',
    'throat',
    'trainer',
    'uncle',
    'youth',
    'time',
    'work',
    'film',
    'water',
    'money',
    'example',
    'while',
    'business',
    'study',
    'game',
    'life',
    'form',
    'air',
    'day',
    'place',
    'number',
    'part',
    'field',
    'fish',
    'back',
    'process',
    'heat',
    'hand',
    'experience',
    'job',
    'book',
    'end',
    'point',
    'type',
    'home',
    'economy',
    'value',
    'body',
    'market',
    'guide',
    'interest',
    'state',
    'radio',
    'course',
    'company',
    'price',
    'size',
    'card',
    'list',
    'mind',
    'trade',
    'line',
    'care',
    'group',
    'risk',
    'word',
    'fat',
    'force',
    'key',
    'light',
    'training',
    'name',
    'school',
    'top',
    'amount',
    'level',
    'order',
    'practice',
    'research',
    'sense',
    'service',
    'piece',
    'web',
    'boss',
    'sport',
    'fun',
    'house',
    'page',
    'term',
    'test',
    'answer',
    'sound',
    'focus',
    'matter',
    'kind',
    'soil',
    'board',
    'oil',
    'picture',
    'access',
    'garden',
    'range',
    'rate',
    'reason',
    'future',
    'site',
    'demand',
    'exercise',
    'image',
    'case',
    'cause',
    'coast',
    'action',
    'age',
    'bad',
    'boat',
    'record',
    'result',
    'section',
    'building',
    'mouse',
    'cash',
    'class',
    'nothing',
    'period',
    'plan',
    'store',
    'tax',
    'side',
    'subject',
    'space',
    'rule',
    'stock',
    'weather',
    'chance',
    'figure',
    'man',
    'model',
    'source',
    'beginning',
    'earth',
    'program',
    'chicken',
    'design',
    'feature',
    'head',
    'material',
    'purpose',
    'question',
    'rock',
    'salt',
    'act',
    'birth',
    'car',
    'dog',
    'object',
    'scale',
    'sun',
    'note',
    'profit',
    'rent',
    'speed',
    'style',
    'war',
    'bank',
    'craft',
    'half',
    'inside',
    'outside',
    'standard',
    'bus',
    'exchange',
    'eye',
    'fire',
    'position',
    'pressure',
    'stress',
    'advantage',
    'benefit',
    'box',
    'frame',
    'issue',
    'step',
    'cycle',
    'face',
    'item',
    'metal',
    'paint',
    'review',
    'room',
    'screen',
    'structure',
    'view',
    'account',
    'ball',
    'discipline',
    'medium',
    'share',
    'balance',
    'bit',
    'black',
    'bottom',
    'choice',
    'gift',
    'impact',
    'machine',
    'shape',
    'tool',
    'wind',
    'address',
    'average',
    'career',
    'culture',
    'morning',
    'pot',
    'sign',
    'table',
    'task',
    'condition',
    'contact',
    'credit',
    'egg',
    'hope',
    'ice',
    'network',
    'north',
    'square',
    'attempt',
    'date',
    'effect',
    'link',
    'post',
    'star',
    'voice',
    'capital',
    'challenge',
    'friend',
    'self',
    'shot',
    'brush',
    'couple',
    'debate',
    'exit',
    'front',
    'function',
    'lack',
    'living',
    'plant',
    'plastic',
    'spot',
    'summer',
    'taste',
    'theme',
    'track',
    'wing',
    'brain',
    'button',
    'click',
    'desire',
    'foot',
    'gas',
    'influence',
    'notice',
    'rain',
    'wall',
    'base',
    'damage',
    'distance',
    'feeling',
    'pair',
    'savings',
    'staff',
    'sugar',
    'target',
    'text',
    'animal',
    'author',
    'budget',
    'discount',
    'file',
    'ground',
    'lesson',
    'minute',
    'officer',
    'phase',
    'reference',
    'register',
    'sky',
    'stage',
    'stick',
    'title',
    'trouble',
    'bowl',
    'bridge',
    'campaign',
    'character',
    'club',
    'edge',
    'evidence',
    'fan',
    'letter',
    'lock',
    'maximum',
    'novel',
    'option',
    'pack',
    'park',
    'plenty',
    'quarter',
    'skin',
    'sort',
    'weight',
    'baby',
    'background',
    'carry',
    'dish',
    'factor',
    'fruit',
    'glass',
    'joint',
    'master',
    'muscle',
    'red',
    'strength',
    'traffic',
    'trip',
    'vegetable',
    'appeal',
    'chart',
    'gear',
    'ideal',
    'kitchen',
    'land',
    'log',
    'mother',
    'net',
    'party',
    'principle',
    'relative',
    'sale',
    'season',
    'signal',
    'spirit',
    'street',
    'tree',
    'wave',
    'belt',
    'bench',
    'commission',
    'copy',
    'drop',
    'minimum',
    'path',
    'progress',
    'project',
    'sea',
    'south',
    'status',
    'stuff',
    'ticket',
    'tour',
    'angle',
    'blue',
    'breakfast',
    'confidence',
    'daughter',
    'degree',
    'doctor',
    'dot',
    'dream',
    'duty',
    'essay',
    'father',
    'fee',
    'finance',
    'hour',
    'juice',
    'limit',
    'luck',
    'milk',
    'mouth',
    'peace',
    'pipe',
    'seat',
    'stable',
    'storm',
    'substance',
    'team',
    'trick',
    'afternoon',
    'bat',
    'beach',
    'blank',
    'catch',
    'chain',
    'consideration',
    'cream',
    'crew',
    'detail',
    'gold',
    'interview',
    'kid',
    'mark',
    'match',
    'mission',
    'pain',
    'pleasure',
    'score',
    'screw',
    'sex',
    'shop',
    'shower',
    'suit',
    'tone',
    'window',
    'agent',
    'band',
    'block',
    'bone',
    'calendar',
    'cap',
    'coat',
    'contest',
    'corner',
    'court',
    'cup',
    'district',
    'door',
    'east',
    'finger',
    'garage',
    'guarantee',
    'hole',
    'hook',
    'implement',
    'layer',
    'lecture',
    'lie',
    'manner',
    'meeting',
    'nose',
    'parking',
    'partner',
    'profile',
    'respect',
    'rice',
    'routine',
    'schedule',
    'swimming',
    'telephone',
    'tip',
    'winter',
    'airline',
    'bag',
    'battle',
    'bed',
    'bill',
    'bother',
    'cake',
    'code',
    'curve',
    'designer',
    'dimension',
    'dress',
    'ease',
    'emergency',
    'evening',
    'extension',
    'farm',
    'fight',
    'gap',
    'grade',
    'holiday',
    'horror',
    'horse',
    'host',
    'husband',
    'loan',
    'mistake',
    'mountain',
    'nail',
    'noise',
    'occasion',
    'package',
    'patient',
    'pause',
    'phrase',
    'proof',
    'race',
    'relief',
    'sand',
    'sentence',
    'shoulder',
    'smoke',
    'stomach',
    'string',
    'tourist',
    'towel',
    'vacation',
    'west',
    'wheel',
    'wine',
    'arm',
    'aside',
    'associate',
    'bet',
    'blow',
    'border',
    'branch',
    'breast',
    'brother',
    'buddy',
    'bunch',
    'chip',
    'coach',
    'cross',
    'document',
    'draft',
    'dust',
    'expert',
    'floor',
    'god',
    'golf',
    'habit',
    'iron',
    'judge',
    'knife',
    'landscape',
    'league',
    'mail',
    'mess',
    'native',
    'opening',
    'parent',
    'pattern',
    'pin',
    'pool',
    'pound',
    'request',
    'salary',
    'shame',
    'shelter',
    'shoe',
    'silver',
    'tackle',
    'tank',
    'trust',
    'assist',
    'bake',
    'bar',
    'bell',
    'bike',
    'blame',
    'boy',
    'brick',
    'chair',
    'closet',
    'clue',
    'collar',
    'comment',
    'conference',
    'devil',
    'diet',
    'fear',
    'fuel',
    'glove',
    'jacket',
    'lunch',
    'monitor',
    'mortgage',
    'nurse',
    'pace',
    'panic',
    'peak',
    'plane',
    'reward',
    'row',
    'sandwich',
    'shock',
    'spite',
    'spray',
    'surprise',
    'till',
    'transition',
    'weekend',
    'welcome',
    'yard',
    'alarm',
    'bend',
    'bicycle',
    'bite',
    'blind',
    'bottle',
    'cable',
    'candle',
    'clerk',
    'cloud',
    'concert',
    'counter',
    'flower',
    'grandfather',
    'harm',
    'knee',
    'lawyer',
    'leather',
    'load',
    'mirror',
    'neck',
    'pension',
    'plate',
    'purple',
    'ruin',
    'ship',
    'skirt',
    'slice',
    'snow',
    'specialist',
    'stroke',
    'switch',
    'trash',
    'tune',
    'zone',
    'anger',
    'award',
    'bid',
    'bitter',
    'boot',
    'bug',
    'camp',
    'candy',
    'carpet',
    'cat',
    'champion',
    'channel',
    'clock',
    'comfort',
    'cow',
    'crack',
    'engineer',
    'entrance',
    'fault',
    'grass',
    'guy',
    'hell',
    'highlight',
    'incident',
    'island',
    'joke',
    'jury',
    'leg',
    'lip',
    'mate',
    'motor',
    'nerve',
    'passage',
    'pen',
    'pride',
    'priest',
    'prize',
    'promise',
    'resident',
    'resort',
    'ring',
    'roof',
    'rope',
    'sail',
    'scheme',
    'script',
    'sock',
    'station',
    'toe',
    'tower',
    'truck',
    'witness',
    'a',
    'you',
    'it',
    'can',
    'will',
    'if',
    'one',
    'many',
    'most',
    'other',
    'use',
    'make',
    'good',
    'look',
    'help',
    'go',
    'great',
    'being',
    'few',
    'might',
    'still',
    'public',
    'read',
    'keep',
    'start',
    'give',
    'human',
    'local',
    'general',
    'she',
    'specific',
    'long',
    'play',
    'feel',
    'high',
    'tonight',
    'put',
    'common',
    'set',
    'change',
    'simple',
    'past',
    'big',
    'possible',
    'particular',
    'today',
    'major',
    'personal',
    'current',
    'national',
    'cut',
    'natural',
    'physical',
    'show',
    'try',
    'check',
    'second',
    'call',
    'move',
    'pay',
    'let',
    'increase',
    'single',
    'individual',
    'turn',
    'ask',
    'buy',
    'guard',
    'hold',
    'main',
    'offer',
    'potential',
    'professional',
    'international',
    'travel',
    'cook',
    'alternative',
    'following',
    'special',
    'working',
    'whole',
    'dance',
    'excuse',
    'cold',
    'commercial',
    'low',
    'purchase',
    'deal',
    'primary',
    'worth',
    'fall',
    'necessary',
    'positive',
    'produce',
    'search',
    'present',
    'spend',
    'talk',
    'creative',
    'tell',
    'cost',
    'drive',
    'green',
    'support',
    'glad',
    'remove',
    'return',
    'run',
    'complex',
    'due',
    'effective',
    'middle',
    'regular',
    'reserve',
    'independent',
    'leave',
    'original',
    'reach',
    'rest',
    'serve',
    'watch',
    'beautiful',
    'charge',
    'active',
    'break',
    'negative',
    'safe',
    'stay',
    'visit',
    'visual',
    'affect',
    'cover',
    'report',
    'rise',
    'walk',
    'white',
    'beyond',
    'junior',
    'pick',
    'unique',
    'anything',
    'classic',
    'final',
    'lift',
    'mix',
    'private',
    'stop',
    'teach',
    'western',
    'concern',
    'familiar',
    'fly',
    'official',
    'broad',
    'comfortable',
    'gain',
    'maybe',
    'rich',
    'save',
    'stand',
    'young',
    'fail',
    'heavy',
    'hello',
    'lead',
    'listen',
    'valuable',
    'worry',
    'handle',
    'leading',
    'meet',
    'release',
    'sell',
    'finish',
    'normal',
    'press',
    'ride',
    'secret',
    'spread',
    'spring',
    'tough',
    'wait',
    'brown',
    'deep',
    'display',
    'flow',
    'hit',
    'objective',
    'shoot',
    'touch',
    'cancel',
    'chemical',
    'cry',
    'dump',
    'extreme',
    'push',
    'conflict',
    'eat',
    'fill',
    'formal',
    'jump',
    'kick',
    'opposite',
    'pass',
    'pitch',
    'remote',
    'total',
    'treat',
    'vast',
    'abuse',
    'beat',
    'burn',
    'deposit',
    'print',
    'raise',
    'sleep',
    'somewhere',
    'advance',
    'anywhere',
    'consist',
    'dark',
    'double',
    'draw',
    'equal',
    'fix',
    'hire',
    'internal',
    'join',
    'kill',
    'sensitive',
    'tap',
    'win',
    'attack',
    'claim',
    'constant',
    'drag',
    'drink',
    'guess',
    'minor',
    'pull',
    'raw',
    'soft',
    'solid',
    'wear',
    'weird',
    'wonder',
    'annual',
    'count',
    'dead',
    'doubt',
    'feed',
    'forever',
    'impress',
    'nobody',
    'repeat',
    'round',
    'sing',
    'slide',
    'strip',
    'whereas',
    'wish',
    'combine',
    'command',
    'dig',
    'divide',
    'equivalent',
    'hang',
    'hunt',
    'initial',
    'march',
    'mention',
    'smell',
    'spiritual',
    'survey',
    'tie',
    'adult',
    'brief',
    'crazy',
    'escape',
    'gather',
    'hate',
    'prior',
    'repair',
    'rough',
    'sad',
    'scratch',
    'sick',
    'strike',
    'employ',
    'external',
    'hurt',
    'illegal',
    'laugh',
    'lay',
    'mobile',
    'nasty',
    'ordinary',
    'respond',
    'royal',
    'senior',
    'split',
    'strain',
    'struggle',
    'swim',
    'train',
    'upper',
    'wash',
    'yellow',
    'convert',
    'crash',
    'dependent',
    'fold',
    'funny',
    'grab',
    'hide',
    'miss',
    'permit',
    'quote',
    'recover',
    'resolve',
    'roll',
    'sink',
    'slip',
    'spare',
    'suspect',
    'sweet',
    'swing',
    'twist',
    'upstairs',
    'usual',
    'abroad',
    'brave',
    'calm',
    'concentrate',
    'estimate',
    'grand',
    'male',
    'mine',
    'prompt',
    'quiet',
    'refuse',
    'regret',
    'reveal',
    'rush',
    'shake',
    'shift',
    'shine',
    'steal',
    'suck',
    'surround',
    'anybody',
    'bear',
    'brilliant',
    'dare',
    'dear',
    'delay',
    'drunk',
    'female',
    'hurry',
    'inevitable',
    'invite',
    'kiss',
    'neat',
    'pop',
    'punch',
    'quit',
    'reply',
    'representative',
    'resist',
    'rip',
    'rub',
    'silly',
    'smile',
    'spell',
    'stretch',
    'stupid',
    'tear',
    'temporary',
    'tomorrow',
    'wake',
    'wrap',
    'yesterday',
  ],
}

const MAIN_NAMES = NAME_LIST.top100us
  .concat(NAME_LIST.germanNames)
  .concat(NAME_LIST.globalNames)
  .concat(NAME_LIST.general)

const MANUAL_TAGGED_COLORS = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'pink', 'brown(ish)', 'other']

const MANUALLY_TAGGED_SCORP_COLORS = {
  '#2F0043': ['purple'],
  '#2F00FF': ['blue'],
  '#0000FF': ['blue'],
  '#6D0000': ['red'],
  '#6D0043': ['red'],
  '#6D00FF': ['purple'],
  '#B40043': ['red', 'pink'],
  '#000043': ['blue'],
  '#002F00': ['green'],
  '#6D009B': ['purple'],
  '#2F009B': ['blue'],
  '#FF2F00': ['red', 'orange'],
  '#2F0000': ['red'],
  '#006D00': ['green'],
  '#00009B': ['blue'],
  '#002F43': ['blue'],
  '#006D9B': ['blue'],
  '#2F2F00': ['brown(ish)'],
  '#FF009B': ['pink'],
  '#002FFF': ['blue'],
  '#FF0043': ['red'],
  '#2FFF00': ['green'],
  '#002F9B': ['blue'],
  '#6D2F00': ['brown(ish)'],
  '#00B443': ['green'],
  '#B40000': ['red'],
  '#FF6D00': ['orange'],
  '#2F6D00': ['green'],
  '#2FB400': ['green'],
  '#FF0000': ['red'],
  '#006D43': ['green'],
  '#6D6D00': ['brown(ish)'],
  '#FFFF00': ['yellow'],
  '#00B400': ['green'],
  '#B42F00': ['red', 'orange', 'brown(ish)'],
  '#00FF43': ['green'],
  '#00B4FF': ['blue'],
  '#00FFFF': ['blue'],
  '#B4FF00': ['green'],
  '#006DFF': ['blue'],
  '#6DFF00': ['green'],
  '#FF00FF': ['pink'],
  '#B4009B': ['pink'],
  '#00B49B': ['green'],
  '#00FF00': ['green'],
  '#FFB400': ['orange'],
  '#6DB400': ['green'],
  '#B4B400': ['yellow'],
  '#B400FF': ['purple'],
  '#00FF9B': ['green'],
  '#B46D00': ['brown(ish)', 'orange'],
  '#2F2FFF': ['blue'],
  '#2F6D43': ['green'],
  '#6D6DFF': ['blue'],
  '#2FFF43': ['green'],
  '#2F2F9B': ['blue'],
  '#6DFFFF': ['blue'],
  '#2F6D9B': ['blue'],
  '#2F2F43': ['other'],
  '#6D2F43': ['red', 'brown(ish)'],
  '#B42F43': ['red'],
  '#B46D43': ['brown(ish)'],
  '#B4FF9B': ['green'],
  '#6DFF9B': ['green'],
  '#B42F9B': ['purple', 'pink'],
  '#B4FF43': ['green'],
  '#B42FFF': ['purple'],
  '#6D6D43': ['other', 'brown(ish)'],
  '#B4B49B': ['other', 'brown(ish)'],
  '#FF2F43': ['red'],
  '#FF2F9B': ['red', 'pink'],
  '#B46DFF': ['purple'],
  '#B46D9B': ['pink', 'purple'],
  '#6DB443': ['green'],
  '#6DB49B': ['green'],
  '#FFB49B': ['pink'],
  '#FF6D43': ['orange'],
  '#2FFF9B': ['green'],
  '#6D2FFF': ['blue'],
  '#6D6D9B': ['blue'],
  '#FF6D9B': ['pink'],
  '#FF2FFF': ['pink'],
  '#FFB4FF': ['pink'],
  '#6DB4FF': ['blue'],
  '#B4B4FF': ['purple'],
  '#2FB49B': ['green'],
  '#2FB4FF': ['blue'],
  '#6DFF43': ['green'],
  '#2FB443': ['green'],
  '#FF6DFF': ['pink'],
  '#B4FFFF': ['blue'],
  '#2F6DFF': ['blue'],
  '#FFB443': ['orange'],
  '#FFFF43': ['yellow'],
  '#B4B443': ['yellow'],
  '#FFFF9B': ['yellow'],
  '#2FFFFF': ['blue'],
  '#6D2F9B': ['purple'],
  '#FFFFFF': ['other'],
  '#000000': ['other'],
}

export {
  SCORP_COLORS,
  SORTED_COLOR_DISTRIBUTION,
  NAME_LIST,
  MAIN_NAMES,
  MANUAL_TAGGED_COLORS,
  MANUALLY_TAGGED_SCORP_COLORS,
}
